<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Meter</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <label for="mode">Mode</label>
                  <v-radio-group
                    v-model="form.mode"
                    row
                  >
                    <v-radio
                      label="Manual"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      label="Automatic"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>

                  <small
                    v-show="form.errors.has('mode')"
                    class="validation-error"
                  >{{ form.errors.get('mode') }}</small>
                </v-col>
                <v-col cols="12">
                  <v-expand-transition>
                    <div
                      v-show="showAutomaticMeterFields"
                      class="automatic"
                    >
                      <v-col cols="12">
                        <v-select
                          v-model="form.type_id"
                          mt-50
                          :items="types"
                          item-text="name"
                          item-value="id"
                          label="Type"
                          :loading="isTypesLoading"
                        ></v-select>

                        <small
                          v-show="form.errors.has('type_id')"
                          class="validation-error"
                        >{{
                          form.errors.get('type_id')
                        }}</small>
                      </v-col>
                      <v-col
                        v-show="action === 'Save'"
                        cols="6"
                      >
                        <label for="mode">Valve Status</label>
                        <v-switch
                          v-model="form.valve_status"
                          inset
                          :false-value="0"
                          :true-value="1"
                          :label="form.valve_status ? 'Open' : 'Close'"
                        ></v-switch>

                        <small
                          v-show="form.errors.has('valve_status')"
                          class="validation-error"
                        >{{
                          form.errors.get('valve_status')
                        }}</small>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="form.sim_card_number"
                          label="Sim card number (Optional)"
                          placeholder="07******"
                          :rules="meterPhoneRules"
                          required
                        ></v-text-field>

                        <small
                          v-show="form.errors.has('sim_card_number')"
                          class="validation-error"
                        >{{
                          form.errors.get('sim_card_number')
                        }}</small>
                      </v-col>
                    </div>
                  </v-expand-transition>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.number"
                    label="Meter number"
                    placeholder="0000******"
                    :rules="meterNumberRules"
                    number
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('number')"
                    class="validation-error"
                  >{{
                    form.errors.get('number')
                  }}</small>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.last_reading"
                    label="Last reading"
                    placeholder="130"
                    :rules="numberRulesAllowZero"
                    hint="The current meter reading"
                    number
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('last_reading')"
                    class="validation-error"
                  >{{
                    form.errors.get('last_reading')
                  }}</small>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="form.station_id"
                    mt-50
                    :items="stations"
                    item-text="name"
                    item-value="id"
                    label="Station"
                    :loading="isStationsLoading"
                  ></v-select>

                  <small
                    v-show="form.errors.has('station_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('station_id')
                  }}</small>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.location"
                    label="Location"
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('location')"
                    class="validation-error"
                  >{{
                    form.errors.get('location')
                  }}</small>
                </v-col>
                <v-col
                  cols="8"
                  class="mb-0 pb-0"
                >
                  <v-checkbox
                    v-model="form.has_location_coordinates"
                    :false-value="0"
                    :true-value="1"
                    label="Add meter location coordinates"
                  ></v-checkbox>

                  <small
                    v-show="form.errors.has('should_pay_connection_fee')"
                    class="validation-error"
                  >{{
                    form.errors.get('should_pay_connection_fee')
                  }}</small>
                </v-col>

                <v-expand-transition>
                  <v-row
                    v-show="form.has_location_coordinates"
                  >
                    <v-container>
                      <v-row class="ml-6">
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.location_coordinates.lat"
                            label="Latitude"
                            placeholder="-1.******"
                            :rules="requiredRules"
                          ></v-text-field>
                          <small
                            v-show="form.errors.has('location_coordinates.lat')"
                            class="validation-error"
                          >{{
                            form.errors.get('location_coordinates.lat')
                          }}</small>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.location_coordinates.lng"
                            label="Longitude"
                            placeholder="36.******"
                            :rules="requiredRules"
                          ></v-text-field>
                          <small
                            v-show="form.errors.has('location_coordinates.lng')"
                            class="validation-error"
                          >{{
                            form.errors.get('location_coordinates.lng')
                          }}</small>
                        </v-col>
                        <v-col
                          cols="12"
                          class="mb-6"
                        >
                          <label
                            for="location-picker"
                          >Search the location name or drag the location picker to select a location.</label>
                          <location-picker
                            :key="meter.id"
                            v-model="form.location_coordinates"
                            class="mt-2"
                            :options="mapOptions"
                          >
                          </location-picker>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-row>
                </v-expand-transition>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import { LocationPicker } from 'vue2-location-picker'
import { mapActions, mapGetters } from 'vuex'
import validationRules from '@/mixins/validationRules'

export default {
  components: { LocationPicker },
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    meter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      phoneRules: [
        v => !v || v.startsWith('07') || v.startsWith('011') || 'Phone number must start with 07 or 011'],
      form: new Form({
        number: '',
        station_id: '',
        mode: '',
        meter_id: '',
        type_id: '',
        sim_card_number: '',
        last_reading: '',
        valve_status: 1,
        location: '',
        has_location_coordinates: 0,
        location_coordinates: {
          lat: -1.2999003,
          lng: 36.7910073,
        },
      }),
      stations: [],
      types: [],
      isStationsLoading: true,
      isTypesLoading: true,
      action: 'Save',
      mapOptions: {
        map: {
          streetViewControl: false,
          styles: this.mapStyles,
        },
        marker: { /** marker options * */ },
        autocomplete: { /** autocomplete options * */ },
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation', 'isDarkThemeEnabled']),
    meters_url() {
      return `meters?station_id=${this.selectedStation}`
    },
    meterNumberRule() {
      const rules = [
        v => !!v || 'Meter number is required',
        v => Number.isInteger(Number(v)) || 'Meter number must be an integer number',
      ]
      if (this.form.mode === 0 || this.form.mode === '' || this.getSelectedTypeName() === 'Prepaid') {
        return rules
      }
      rules.push(v => !v || v.startsWith('0000') || 'Meter number must start with four zeros')

      return rules
    },
    showAutomaticMeterFields() {
      return this.form.mode === 1
    },
    mapStyles() {
      if (this.isDarkThemeEnabled) {
        return [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ]
      }

      return []
    },
  },
  watch: {
    meter() {
      if (Object.keys(this.meter).length > 0) {
        this.form.update(this.meter)
        this.form.location_coordinates.lat = parseFloat(this.meter.lat)
        this.form.location_coordinates.lng = parseFloat(this.meter.lng)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if (value && this.stations.length === 0) {
        this.getStations()
        this.getTypes()
      }
    },
  },
  methods: {
    ...mapActions(['setSelectedStation']),
    closeDialog() {
      this.$emit('close')
      this.form.number = ''
      this.form.mode = ''
      this.form.meter_id = ''
      this.form.type_id = ''
      this.form.sim_card_number = ''
      this.form.last_reading = ''
      this.form.location = ''
      this.$refs.form.resetValidation()
    },
    getSelectedTypeName() {
      const { types } = this
      let name = null

      // TODO:: replace foreach with an alternative that can break
      types.forEach(type => {
        if (type.id === this.form.type_id) {
          name = type.name
        }
      })

      return name
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveMeter()
        } else {
          this.updateMeter()
        }
      }
    },
    getStations() {
      this.isStationsLoading = true
      axios
        .get('meter-stations')
        .then(response => {
          this.stations = response.data
          this.form.station_id = this.selectedStation
          this.isStationsLoading = false
        })
        .catch(error => {
          this.isStationsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getTypes() {
      this.isTypesLoading = true
      axios
        .get('meter-types')
        .then(response => {
          this.types = response.data
          this.isTypesLoading = false
        })
        .catch(error => {
          this.isTypesLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveMeter() {
      this.form
        .post('meters')
        .then(() => {
          this.setSelectedStation(this.form.station_id)
          this.$notification.success('Meter added successfully')
          this.$emit('meter-added')
          this.closeDialog()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateMeter() {
      this.form
        .put(`meters/${this.form.id}`)
        .then(() => {
          this.$notification.success('Meter updated successfully')
          this.$emit('meter-added')
          this.closeDialog()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>

<style>
  #pac-input {
    background-color: #BDBDBD !important;
  }
</style>
